<template>
        <div class="view">
          <v-breadcrumbs>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
              >Home</v-breadcrumbs-item
            >
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'inventoryLabResult', params: {} }">
            Inventory Lab Result
            </v-breadcrumbs-item>
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item :to="{ name: 'inventoryLabResultView', params: {} }">
              View Inventory Lab Result
            </v-breadcrumbs-item>
          </v-breadcrumbs>

          <v-card v-if="loaded">
            <v-card-title>
              View Inventory Lab Result
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    
          <tr v-if="inventoryLabResult.inventoryId">
            <td max-width="35%" class="text-subtitle-1">Inventory ID:</td>
            <td>{{ inventoryLabResult.inventoryId }}</td>
          </tr>
          
          <tr v-if="inventoryLabResult.sampleName">
            <td max-width="35%" class="text-subtitle-1">Sample Name:</td>
            <td>{{ inventoryLabResult.sampleName }}</td>
          </tr>
          
          <tr v-if="inventoryLabResult.quality">
            <td max-width="35%" class="text-subtitle-1">Inventory Lab Result:</td>
            <td>{{ inventoryLabResult.quality }}</td>
          </tr>
          
          <tr v-if="inventoryLabResult.averoutN">
            <td max-width="35%" class="text-subtitle-1">AveroutN:</td>
            <td>{{ inventoryLabResult.averoutN }}</td>
          </tr>
          
          <tr v-if="inventoryLabResult.program">
            <td max-width="35%" class="text-subtitle-1">Program:</td>
            <td>{{ inventoryLabResult.program }}</td>
          </tr>
          
          <tr v-if="inventoryLabResult.technician">
            <td max-width="35%" class="text-subtitle-1">Technician:</td>
            <td>{{ inventoryLabResult.technician }}</td>
          </tr>
          
          <tr v-if="inventoryLabResult.testTime">
            <td max-width="35%" class="text-subtitle-1">Test Time:</td>
            <td>{{ inventoryLabResult.testTime }}</td>
          </tr>
          
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <div class="d-flex justify-space-between ma-5">
            <v-btn @click="goBack">Back</v-btn>
            <v-btn @click="edit(id)" color="primary">Edit</v-btn>
            <span></span>
          </div>
      </div>
    </template>

    <script>
    import { getInventoryLabResult } from "@/services/inventoryLabResult";
    

    export default {
      data() {
        return {
            id: '',
            inventoryLabResult: {},
            loaded: false,
            relations: {}
        }
      },
      methods : {
        goBack() {
          this.$router.go(-1);
        },
        edit(id) {
          this.$router.push({ name: "inventoryLabResultEdit", params: { id: id }});
        },
        setInstance() {
          this.setDependencies().then( () => {
            getInventoryLabResult(this.id)
            .then((response) => {
              this.inventoryLabResult = response.data;
              this.loaded = true;
            })
            .catch( () => {
              this.addErrorMessage("Error loading inventoryLabResult");
              this.loaded = true;
            });
          })
        },
        async setDependencies() {
          return null;
        },
        getRelationText(id, relationProperty, relationAttribute) {
          const relationList = this.relations[relationProperty];
          if (relationList) {
            const relationObj = relationList.find(r => r.id == id);
            return relationObj[relationAttribute];
          } else {
            return "";
          }
        }
      },
      created() {
        this.id = this.$route.params.id;
        this.setInstance();
        this.$emit("showParent", false);
      }
    }
    </script>
    