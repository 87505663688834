<template>
  <v-sheet class="mx-5">
    <transition name="fade">
      <router-view @showParent="showParent"> </router-view>
    </transition>

    <div v-if="showParentPage">
      <v-breadcrumbs>
        <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
          >Home</v-breadcrumbs-item
        >
        <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
        <v-breadcrumbs-item
          :exact="true"
          :to="{ name: 'inventoryLabResult', params: {} }"
          active
        >
          Inventory Lab Result
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <div class="d-flex flex-column flex-lg-row">
        <v-text-field
          dense
          v-model="searchTerms"
          @keyup.enter="search"
          @click:clear="clearSearch"
          @click:append="search"
          placeholder="Press enter to begin search"
          append-icon="mdi-magnify"
          outlined
          clearable
          label="Search"
        />
        <div class="d-flex justify-space-around">
          <v-btn
            class="ml-0 ml-lg-4 mb-2 mb-lg-0 align-self-end align-self-lg-start"
            color="primary"
            :to="{ name: 'inventoryLabResultCreate', params: {} }"
          >
            New Lab Result
          </v-btn>
          <v-btn
            class="ml-0 ml-lg-4 mb-2 mb-lg-0 align-self-end align-self-lg-start"
            color="primary"
            :to="{ name: 'inventoryLabResultUpload', params: {} }"
          >
            Upload
          </v-btn>
        </div>
      </div>
      <v-divider class="my-2" />
      <div class="d-flex justify-space-around flex-column flex-md-row">
        <div class="d-flex">
          <div class="font-weight-light mr-4 mt-1">Display</div>
          <v-switch @click="search" class="mt-0 mx-2" :value="true" label="Unassigned Only" v-model="unassignedOnly"/>
          <v-switch @click="search" class="mt-0 mx-2" :value="true" label="Final Test Only" v-model="finalTestOnly"/>
        </div>
        <download v-if="inventoryLabResultList && inventoryLabResultList.length > 0" v-model="inventoryLabResultList" fileName="Inventory-Lab-Results"/>
      </div>

      <div class="table-container">
        <div class="d-flex justify-end my-2">
        </div>
        <v-data-table
          id="lab-results-table"
          :headers="headers"
          :items="inventoryLabResultList"
          :options.sync="options"
          :items-per-page="pagination.pageSize"
          :loading="!inventoryLabResultProcessed"
          :server-items-length="pagination.totalSize"
          :page="pagination.current"
          item-key="id"
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50, 100, 500]
          }"
          @update:options="pageChanged"
        >
          <template v-slot:item.inventoryId="{ item }">
            <a
              v-if="item.inventoryId"
              @click="
                lookupInventory = true;
                selectedRow = item;
                selectedInventory = item.inventoryId;
              "
              >{{ item.inventoryId }}</a
            >
            <a
              v-else
              style="white-space: nowrap"
              @click="
                lookupInventory = true;
                selectedRow = item;
              "
              >Assign</a
            >
          </template>
          <template v-slot:item.testTime="{ item }">
            <a style="white-space: nowrap" @click="view(item)">{{
              item.testTime | formatDateTimeYear
            }}</a>
          </template>
          <template v-slot:item.finalTest="{ item }">
            <v-icon v-if="item.finalTest">mdi-check</v-icon>
            <v-icon v-else>mdi-close</v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="white-space: nowrap">
              <v-icon small class="mr-2" @click="edit(item)">
                mdi-pencil
              </v-icon>
              <v-icon small class="mr-2" @click="remove(item)">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <div class="my-5">
          <router-link :to="{ name: 'inventoryCoaCheck', params: {} }"
            >Find inventory without CoA</router-link
          >
        </div>
      </div>
    </div>
    <confirmation-dialog ref="confirm" />
    <inventory-lookup
      :activated.sync="lookupInventory"
      v-model="selectedInventory"
      @selected="assignInventory"
    />
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const InventoryLookup = () =>
  import("@/components/inventory/InventoryLookup.vue");
const Download = () => import("@/components/common/Download.vue");

export default {
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 100,
      },
      inventoryLabResultList: [],
      showParentPage: true,
      lookupInventory: false,
      selectedInventory: null,
      selectedRow: null,
      elements: [],
      options: {
        sortBy: ["testTime"],
        sortDesc: ["true"],
      },
      headers: [],
      baseHeaders: [
        {
          text: "Inventory ID",
          value: "inventoryId",
          align: "start",
          sortable: true,
        },
        {
          text: "Test Time",
          value: "testTime",
          align: "start",
          sortable: true,
        },
        {
          text: "Sample Name",
          value: "sampleName",
          align: "start",
          sortable: true,
        },
        {
          text: "Quality",
          value: "quality",
          align: "start",
          sortable: true,
        },
        {
          text: "AveroutN",
          value: "averoutN",
          align: "start",
          sortable: true,
        },
        {
          text: "Program",
          value: "program",
          align: "start",
          sortable: true,
        },
        {
          text: "Technician",
          value: "technician",
          align: "start",
          sortable: true,
        },
        {
          text: "Final",
          value: "finalTest",
          align: "start",
          sortable: true,
        },
      ],
      sort: {
        key: null,
      },
      searchTerms: "",
      unassignedOnly: false,
      finalTestOnly: false,
    };
  },
  components: {
    InventoryLookup,
    Download
  },
  filters: {
    negativeNumber: function (value) {
      if (value && value > 0) {
        return value;
      } else if (value < 0) {
        return `<${Math.abs(value)}`;
      } else {
        return 0;
      }
    },
  },
  computed: {
    ...mapGetters("inventoryLabResult", [
      "inventoryLabResultProcessed",
      "getInventoryLabResults",
    ]),
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("inventoryLabResult", [
      "deleteInventoryLabResult",
      "fetchInventoryLabResults",
      "updateInventoryLabResult",
    ]),
    view(item) {
      this.$router.push({
        name: "inventoryLabResultView",
        params: { id: item.id },
      });
    },
    edit(item) {
      this.$router.push({
        name: "inventoryLabResultEdit",
        params: { id: item.id },
      });
    },
    async remove(item) {
      let confirm = await this.$refs.confirm.open(
        "Confirm Delete",
        "Do you want to delete this inventory lab result?"
      );
      if (confirm) {
        this.deleteInventoryLabResult(item.id)
          .then(() => {
            this.addSuccessMessage("Inventory lab result deleted");
            this.getResources();
          })
          .catch(() => {
            this.addErrorMessage("Server response with error ");
          });
      }
    },
    getResources() {
      const sortBy =
        this.options["sortBy"] && this.options["sortBy"].length > 0
          ? this.options.sortBy[0]
          : "createTime";
      const sortDesc =
        this.options["sortDesc"] && this.options["sortDesc"].length > 0
          ? this.options.sortDesc[0]
          : true;
      const params = {
        page:
          this.pagination.pageIndex && this.pagination.pageIndex > 0
            ? this.pagination.pageIndex - 1
            : 0,
        size: this.pagination.pageSize,
        sort: `${sortBy}${sortDesc ? ",desc" : ""}`,
        sampleName: this.searchTerms,
        quality: this.searchTerms,
        averoutN: this.searchTerms,
        program: this.searchTerms,
        inventoryId: isNaN(this.searchTerms) ? null:this.searchTerms,
        unassignedOnly: this.unassignedOnly ? true:false,
        finalTest: this.finalTestOnly ? true:null,
      };
      this.fetchInventoryLabResults(params)
        .then((response) => {
          if (response) {
            this.pagination.totalSize = response.totalElements;
            if (this.pagination.totalSize > 0) {
              this.inventoryLabResultList = response.content;
              this.prepareElementHeaders(
                this.inventoryLabResultList && this.inventoryLabResultList.length > 0 ? this.inventoryLabResultList[0].elements:[]
              );

              this.inventoryLabResultList.forEach((item) => {
                item.elements.forEach((element) => {
                  item[element.element.symbol] = element.compositionPercent;
                });
              });
            } else {
              this.inventoryLabResultList = [];
            }
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
        });
    },
    showParent(show) {
      this.showParentPage = show;
    },
    pageChanged(pagination) {
      this.pagination.pageIndex = pagination.page;
      this.pagination.pageSize = pagination.itemsPerPage;

      this.getResources();
    },
    assignInventory(inventory) {
      const updateLabResult = this.selectedRow;
      updateLabResult.inventoryId = inventory.id;
      this.updateInventoryLabResult(updateLabResult).then(() => {
        this.addSuccessMessage(
          "Inventory assigned to " + updateLabResult.sampleName
        );
      });
    },
    search() {
      this.getResources();
    },
    clearSearch() {
      this.searchTerms = null;
      this.getResources();
    },
    prepareElementHeaders(elements) {
      this.elements = [];
      const headers = [...this.baseHeaders];

      if (elements && elements.length > 0) {
        elements.forEach((element) => {
          headers.push({
            text: element.element.symbol,
            value: element.element.symbol,
            align: "start",
            sortable: false,
          });
        });

        this.elements = elements.map((e) => e.element.symbol);
        headers.push({
          text: "Actions",
          value: "actions",
          align: "end",
          sortable: false,
        });
      }

      this.headers = headers;
    },
  },
  watch: {
    "pagination.numberRegisterForPage": function () {
      this.pagination.current = -1;
    },
  },
  beforeRouteUpdate(to, from, next) {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    this.showParentPage = toDepth < fromDepth;
    if (this.showParentPage) {
      this.getResources();
    }
    next();
  },
};
</script>

<style>
#lab-results-table thead th,
#lab-results-table tbody td {
  white-space: nowrap;
}

#lab-results-table thead th:first-child {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  z-index: 9;
  opacity: 0.95;
  background-color: white;
  overflow: hidden;
  text-overflow: ellipsis;
}

#lab-results-table tbody td:first-child {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  z-index: 9;
  opacity: 0.95;
  background-color: white;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
